import React from "react"
import Layout from "../components/layout.js"
import Meta from "../components/meta.js"
import * as styles from "../pages/404.module.css"

export default function FourOhFour() {
  return (
    <Layout>
      <Meta />
      <img
        src="https://imgur.com/wxou0tT.jpg"
        style={{
          width: `50%`,
          display: `block`,
          marginLeft: `auto`,
          marginRight: `auto`,
          marginTop: `20%`,
        }}
      ></img>
      <p
        className={styles.pp}
        style={{
          fontSize: `1.5rem`,
          fontWeight: `500`,
          textAlign: `center`,
          display: `block`,
          marginLeft: `auto`,
          marginRight: `auto`,
          marginTop: `100px`,
        }}
      >
        This page was not found. Maybe you wanna go back{" "}
        <a href="/">
          <mark>home</mark>
        </a>
        ?
      </p>
    </Layout>
  )
}
